.xHover:hover img{
  transform: scale(1.1);
}

.hidden {
  opacity: 70%;
  display: none;
  position: relative;
}

.xHover:hover .hidden {
  display: inline-block;
  transform: scale(1.1);
  z-index: 999;
}